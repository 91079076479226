import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {StatisticsComponent} from './statistics.component';
import {StatisticsRoutingModule} from './statistics-routing.module';

@NgModule({
  declarations: [
    StatisticsComponent,
  ],
  imports: [
    StatisticsRoutingModule,
    CommonModule,
    FormsModule,
  ],
})
export class StatisticsModule {
}
